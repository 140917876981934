"use client";
import {
  asyncWithLDProvider,
  useFlags as useLdFlags,
} from "launchdarkly-react-client-sdk";
import { getEnv } from "tools";
import { ReactNode, use, useMemo } from "react";

/**
 * LDProvider component that wraps children with LaunchDarkly provider
 *
 * @param children - ReactNode representing the child components
 * @param context - Optional context for the provider
 * @returns JSX.Element - Wrapped children with LaunchDarkly provider
 */
export function LDProvider({ children }: { children: ReactNode }) {
  const Provider = useMemo(
    async () =>
      typeof window !== "undefined"
        ? asyncWithLDProvider({
            clientSideID: getEnv("NEXT_PUBLIC_LD_CLIENT_ID") ?? "",
            options: {
              baseUrl: getEnv("NEXT_PUBLIC_LD_BASE_URL"),
              streamUrl: getEnv("NEXT_PUBLIC_LD_STREAM_URL"),
              eventsUrl: getEnv("NEXT_PUBLIC_LD_EVENTS_URL"),
            },
            timeout: 5,
          })
        : null,
    []
  );

  const LDClientProvider = use(Provider);

  return LDClientProvider ? (
    <LDClientProvider>{children}</LDClientProvider>
  ) : (
    <>{children}</>
  );
}

/**
 * As we add flags in LD make sure to update this type
 */
export type FlagSet = {
  graphServicesListView: boolean;
  newFieldsOnMilitaryBaseScreen: boolean;
  ppmPortal: boolean;
};

/**
 * Typed useFlags from the launch darkly react SDK
 * @returns FlagSet
 */
export const useFlags = () => useLdFlags<FlagSet>();
